/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api91e3e4de",
            "endpoint": "https://1goqzlos3e.execute-api.us-east-1.amazonaws.com/stageone",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://h5yqnm7qjzggviuvebefohx4wq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-nsy23fyqare7no5wcyalteaisq",
    "aws_cognito_identity_pool_id": "us-east-1:9dcc8640-9cbe-43bc-b65e-38c74af5c45a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GXH8BI2TK",
    "aws_user_pools_web_client_id": "1u57brl3atmbvlc77dskh192qb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
